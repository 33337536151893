import axios from 'axios';
import authHeader from './auth-header';

import constants from '../constants';
import { parseError } from '@/utils/utils'
var API_URL = constants.API_URL + 'notification/manual/';

class NotificationManualService {

  async send(data) {
    try {
      let response = await axios.post(API_URL, data, { headers: authHeader() });
      return response;
    }
    catch (error) {
      return { success: false, data: parseError(error) };
    }
  }

}

export default new NotificationManualService();